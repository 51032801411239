import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {useMedia} from "../core/db";

interface MediaModalProps {
    open: boolean,
    mediaId: string | null,
    handleClose: () => void
}


interface MediaModalState {
    open: boolean,
    mediaId: string | null

}

export function useMediaModal(): [MediaModalState, () => void, (mediaId: string) => void]{
    const [mediaModalState, setMediaModalState] = useState({
        open: false,
        mediaId: null as string | null
    });

    const handleMediaModalClose = useCallback(() => {
        setMediaModalState({open: false, mediaId: null})
    }, [])

    const handleMediaModalClick = useCallback((mediaId: string) => {
        setMediaModalState({
            open: true,
            mediaId
        })
    }, [])
    return [mediaModalState, handleMediaModalClose, handleMediaModalClick]
}

export function MediaModal({open, mediaId, handleClose}: MediaModalProps) {
    const [media, mediaLoading, mediaError] = useMedia(mediaId);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        overflow: 'hidden',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {media &&
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{pb:1}}>
                            {media.name}
                        </Typography>
                        <Box
                            sx={{overflowX: 'auto'
                        }}>
                            <video autoPlay={true} controls={true} >
                                <source
                                    src={media.url}
                                    type="video/mp4"/>
                            </video>
                        </Box>
                    </>
                }
            </Box>
        </Modal>
    )
}