import React, {
    createContext,
    Dispatch,
    ReactNode,
    useContext,
} from "react";
import {getPlaylistReducer, PlaylistsAction, PlaylistsState} from "../reducers/playlistsReducer";
import {getMediasReducer, MediasAction, MediasState} from "../reducers/mediasReducer";

interface ApplicationContext {
    playlistsState: PlaylistsState,
    playlistsDispatch: Dispatch<PlaylistsAction>,
    mediasState: MediasState,
    mediasDispatch: Dispatch<MediasAction>,
}

const ApplicationContext = createContext({}) as any as React.Context<ApplicationContext>; // TODO find a fucking solution
const Context = ({children}: { children: ReactNode }) => {

    const [playlistsState, playlistsDispatch] = getPlaylistReducer();
    const [mediasState, mediasDispatch] = getMediasReducer();


    const defaultPlaylistsContext = {
        playlistsState,
        playlistsDispatch,
        mediasState,
        mediasDispatch
    };

    return (
        <ApplicationContext.Provider value={defaultPlaylistsContext}>
            {children}
        </ApplicationContext.Provider>
    );
};

export const ApplicationState = () => {
    return useContext(ApplicationContext);
};

export default Context;

