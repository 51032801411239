import React from 'react'
import ReactDOM from 'react-dom'
import {CssBaseline} from "@mui/material";
import App from "./app";

export default function AppIndex() {
    return (
        <React.Fragment>
            <CssBaseline />
            <App/>
        </React.Fragment>
    );
}

const appContainerDiv = document.createElement('div')
document.body.append(appContainerDiv);

ReactDOM.render(<AppIndex/>, appContainerDiv)