import {List, Map, Record, RecordOf} from "immutable";

export interface TrackData {
    id: string,
    color: string,
    mediaId: string
}

export type Track = RecordOf<TrackData>;
export const trackFactory = Record<TrackData>({
    id: "",
    color: "",
    mediaId: "",
});

export interface PlaylistData {
    id: string,
    name: string,
    tracks: List<Track>,
    deleted: boolean
}

export type Playlist = RecordOf<PlaylistData>;
export const playlistFactory = Record<PlaylistData>({
    id: "",
    name: "",
    tracks: List(),
    deleted: false
});


export function reorderPlaylistTracks(playlist: Playlist, tracksIds: string[]){
    const trackIndex = playlist.tracks.reduce<Map<string, Track>>((trackIndex, track) => {
        return trackIndex.set(track.id, track);
    }, Map());

    const notSortedTracksIds = playlist.tracks.filter((track) => {
        return tracksIds.indexOf(track.id) === -1
    }).map((track) => track.id).toArray();

    const toSortTrackIds = tracksIds.concat(notSortedTracksIds);

    let newTrackList = List<Track>();
    for (let i = 0; i < toSortTrackIds.length; i++) {
        const trackId = tracksIds[i];
        const track = trackIndex.get(trackId);
        if (track) newTrackList = newTrackList.push(track);
    }
    return playlist.set('tracks', newTrackList);
}