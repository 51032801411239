import React, {SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {ApplicationState} from "../core/playlistsContext";
import {
    Autocomplete, Box,
    Button,
    Container,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
    Paper,
    TextField, Typography, CssBaseline, Card, CardContent
} from "@mui/material";
import {useForm} from "react-hook-form";
import {Playlist, playlistFactory, reorderPlaylistTracks, Track, trackFactory} from "../model/playlist";
import Utils from "../core/utils";
import DeleteIcon from '@mui/icons-material/Delete';
import {ContentCopy, DriveFileRenameOutline} from "@mui/icons-material";
import {TrackList} from "../components/TrackList";
import MediaSelector from "../components/MediaSelector";
import ResponsiveAppBar from "../components/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {List, Map, Set} from "immutable";
import {Duration} from "luxon";
import {Media} from "../model/media";
import {usePlaylist, useMedias, usePlaylists, writePlaylist} from "../core/db";

type AskNameFormData = {
    askPlaylistNameInput: string;
};

type PlaylistOption = {
    label: string
    id: string
}

export default function PlayListsPage() {

    const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(null)

    const [playlists, playlistsLoading, playlistsError] = usePlaylists();
    const [medias, mediasLoading, mediasError, mediasIndex] = useMedias();
    const [playlist, playlistLoading, playlistError] = usePlaylist(selectedPlaylistId);

    useEffect(() => {
        if (selectedPlaylistId === null && playlists.size > 0) {
            setSelectedPlaylistId(playlists.get(0)!.id)
        }
    }, [selectedPlaylistId, playlists])

    //////////// Ask name Form //////////
    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
        setValue: setAskFormValue,
        setFocus: setAskFormFocus
    } = useForm<AskNameFormData>();
    const {ref, ...registerParams} = register("askPlaylistNameInput", {required: true, minLength: 1})
    const [askNameState, setAskNameState] = React.useState({
        visible: false,
        playlist: playlist as Playlist | null,
        onConfirm: null as ((name: string) => void) | null,
    });
    const onAskNameCancel = useCallback(() => {
        setAskNameState({visible: false, playlist: null, onConfirm: null});
    }, [])
    const onAskNameSubmit = useCallback((data: AskNameFormData) => {
        askNameState.onConfirm?.(data.askPlaylistNameInput)
        setAskNameState({visible: false, playlist: null, onConfirm: null});
    }, [askNameState])
    ///////////////////////////////////////

    //////////// Ask delete Form ////////

    const [askDeleteModalState, setAskDeleteModalState] = React.useState({
        visible: false,
        playlist: playlist as Playlist | null
    });

    const handleConfirmDelete = useCallback(() => {
        if (askDeleteModalState.playlist) {
            const playlistIdToDelete = askDeleteModalState.playlist.id;
            let playlistPosition = playlists.findIndex((playlist) => playlist.id === playlistIdToDelete);
            let nextPlaylistPosition = playlistPosition + 1;

            if (playlistPosition > playlists.size) {
                playlistPosition = playlists.size - 1;
            }
            const nextPlaylist = playlists.get(nextPlaylistPosition);
            setSelectedPlaylistId(nextPlaylist?.id || null);
            //TODO race condition!
            writePlaylist(askDeleteModalState.playlist.merge({deleted: true})).catch(console.error)
        }
        setAskDeleteModalState({
            visible: false,
            playlist: null
        });
    }, [askDeleteModalState, playlist]);

    const handleCancelDelete = useCallback(() => {
        setAskDeleteModalState({
            visible: false,
            playlist: null
        });
    }, []);

    /////////////////////////////////////

    const createPlaylist = useCallback(() => {
        const name = Utils.getNextName(`Playlist du ${Utils.getNowDayString()}`, playlists.map((playlist) => playlist.name).toArray());
        setAskFormValue('askPlaylistNameInput', name)
        setAskNameState({
            visible: true,
            playlist: playlist,
            onConfirm: (name) => {
                const newId = Utils.uuid();
                writePlaylist(playlistFactory({name, id: newId})).catch(console.error);
                setSelectedPlaylistId(newId);
            }
        })
    }, [playlists])

    const duplicatePlaylist = useCallback(() => {
        if (!playlist) return;
        const prefix = playlist.name.startsWith("Copie de ") ? "" : "Copie de ";    //TODO problem copy of copy
        const name = Utils.getNextName(`${prefix}${playlist.name}`, playlists.map((playlist) => playlist.name).toArray());
        setAskFormValue('askPlaylistNameInput', name)
        setAskNameState({
            visible: true,
            playlist: playlist,
            onConfirm: (name) => {
                if (playlist) {
                    const newId = Utils.uuid();
                    writePlaylist(playlist.merge({name, id: newId})).catch(console.error);
                    setSelectedPlaylistId(newId);
                }
            }
        })
    }, [playlist, playlists])


    const renamePlaylist = useCallback(() => {
        if (!playlist) return;
        setAskFormValue('askPlaylistNameInput', playlist.name)
        setAskNameState({
            visible: true,
            playlist: playlist,
            onConfirm: (name) => {
                writePlaylist(playlist.merge({name})).catch(console.error)
            }
        })
    }, [playlist])

    const deletePlaylist = useCallback(() => {
        setAskDeleteModalState({
            visible: true,
            playlist: playlist
        });
    }, [playlist])


    ////////// Search Playlist ////////////

    const playlistOptions: PlaylistOption[] = useMemo(() => playlists.map((playlist) => {
        return {
            label: playlist.name,
            id: playlist.id
        }
    }).toArray(), [playlists])


    const [searchPlaylistInput, setSearchPlaylistInput] = useState<string>('')
    const onPlaylistSelected = useCallback((event: SyntheticEvent, value: PlaylistOption | null) => {
        if (value !== null) {
            setSelectedPlaylistId(value.id);
        }
        setSearchPlaylistInput('');
    }, [])

    const onInputChange = useCallback((event: SyntheticEvent, value: string) => {
        if (event && document.activeElement === event.target) setSearchPlaylistInput(value)
    }, [])

    ///////////////////////////////////

    ///////////// Tracks  /////////////

    const reorder = (list: string[], originIndex: number, destinationIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(originIndex, 1);
        result.splice(destinationIndex, 0, removed);
        return result;
    };

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) return;
        if (!playlist) return;

        if (result.source.droppableId === 'MEDIAS') {
            const track = trackFactory({
                id: Utils.uuid(),
                mediaId: result.draggableId
            });
            const newTracks = playlist.tracks.insert(result.destination.index, track);
            const newPlaylist = playlist.set('tracks', newTracks);
            writePlaylist(newPlaylist).catch(console.error);
        }

        if (result.source.droppableId === 'TRACKS') {
            const prevTrackIds = playlist.tracks.map((track) => track.id).toArray()
            const reorderedIds = reorder(
                prevTrackIds,
                result.source.index,
                result.destination.index
            );
            writePlaylist(reorderPlaylistTracks(playlist, reorderedIds)).catch(console.error);
        }
    }, [playlist, playlist?.tracks])

    const handleAddMedia = useCallback((media: Media) => {
        if (!playlist) return;
        const track = trackFactory({
            id: Utils.uuid(),
            mediaId: media.id
        });

        const newTracks = playlist.tracks.push(track);
        const newPlaylist = playlist.set('tracks', newTracks);
        writePlaylist(newPlaylist).catch(console.error);
    }, [playlist])

    const totalDuration = useMemo(() => {
        if (!playlist) return 0;
        return playlist.tracks.reduce((total, track) => {
            const media = mediasIndex.get(track.mediaId);
            if (media) total = total + media.duration;
            return total
        }, 0)
    }, [playlist]);

    const totalDurationStr = useMemo(() => {
        return Duration.fromObject({milliseconds: totalDuration}).toFormat("m:ss")
    }, [totalDuration])

    const uniqueMediaIds = useMemo(() => {
        if (!playlist) return Set<string>();
        return playlist.tracks.reduce((mediaIds, track) => {
            return mediaIds.add(track.mediaId);
        }, Set<string>())
    }, [playlist]);


    ///////////////////////////////////


    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Box sx={{display: 'flex'}}>
                    <ResponsiveAppBar/>
                    <MediaSelector handleAddMedia={handleAddMedia}/>
                    <Container sx={{p: 3, flexGrow: 1, backgroundColor: '#f5f5f5'}}>
                        <Toolbar/>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            pb: 1
                        }}>
                            <Typography variant="h4">
                                Playlists
                            </Typography>
                            <Autocomplete
                                sx={{
                                    ml: 3,
                                    flexGrow: 1,
                                    backgroundColor: 'white'
                                }}
                                disablePortal
                                size='small'
                                value={undefined}
                                id="combo-box-demo"
                                options={playlistOptions}
                                //sx={{ width: 300 }}
                                //disableClearable={true}
                                renderInput={(params) => <TextField {...params} label="Chercher une playlist"/>}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                blurOnSelect={true}
                                onChange={onPlaylistSelected}
                                onInputChange={onInputChange}
                                inputValue={searchPlaylistInput}
                            />
                            <Button variant="contained" onClick={createPlaylist}>Créer playlist</Button>

                        </Box>

                        {playlist &&
                            <>
                                <Paper
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        p: 2,
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={10}>
                                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                                {playlist.name}
                                                <IconButton aria-label="Renommer" onClick={renamePlaylist}>
                                                    <DriveFileRenameOutline/>
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{textAlign: "right"}}>
                                            <IconButton aria-label="Supprimer" onClick={deletePlaylist}>
                                                <DeleteIcon/>
                                            </IconButton>
                                            <IconButton aria-label="Copie" onClick={duplicatePlaylist}>
                                                <ContentCopy/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={10}>
                                        <Grid item xs={4}>
                                            <Card sx={{
                                                backgroundColor: "rgba(0, 0, 0, 0.02)"
                                            }}>
                                                <CardContent sx={{
                                                    "&:last-child": {
                                                        paddingBottom: '16px'
                                                    }
                                                }}>
                                                    <Typography color="text.secondary">
                                                        Nombre de pistes
                                                    </Typography>
                                                    <Typography variant="h6" component="div">
                                                        {playlist.tracks.size}
                                                    </Typography>

                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Card sx={{
                                                backgroundColor: "rgba(0, 0, 0, 0.02)"
                                            }}>
                                                <CardContent sx={{
                                                    "&:last-child": {
                                                        paddingBottom: '16px'
                                                    }
                                                }}>
                                                    <Typography color="text.secondary">
                                                        Nombre de médias
                                                    </Typography>
                                                    <Typography variant="h6" component="div">
                                                        {uniqueMediaIds.size}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Card sx={{
                                                backgroundColor: "rgba(0, 0, 0, 0.02)"
                                            }}>
                                                <CardContent sx={{
                                                    "&:last-child": {
                                                        paddingBottom: '16px'
                                                    }
                                                }}>
                                                    <Typography color="text.secondary">
                                                        Durée totale de la playlist
                                                    </Typography>
                                                    <Typography variant="h6" component="div">
                                                        {totalDurationStr}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Paper>


                                <Paper sx={{
                                    mt: 2,
                                    mb: 2,
                                    p: 2,
                                    display: 'flex',
                                    overflowY: 'unset',
                                    flexDirection: 'column'
                                }}>
                                    <TrackList playlistId={playlist.id} trackList={playlist.tracks}/>

                                </Paper>
                            </>
                        }
                    </Container>
                </Box>


                <Dialog
                    open={askNameState.visible}
                    onClose={onAskNameCancel}
                    maxWidth="md"
                    fullWidth={true}
                >
                    <form onSubmit={handleSubmit(onAskNameSubmit)}>
                        <DialogTitle>Nom de la playlist</DialogTitle>
                        <DialogContent>
                            <TextField
                                {...registerParams}
                                ref={(e) => {
                                    ref(e)
                                    e?.getElementsByTagName('input')[0]?.select();
                                }}
                                autoFocus
                                margin="dense"
                                size="medium"
                                name="askPlaylistNameInput"
                                id="askPlaylistNameInput"
                                label="Nom de la playlist"
                                type="text"
                                fullWidth
                                variant="standard"
                                autoComplete="off"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onAskNameCancel}>Annuler</Button>
                            <Button type="submit">OK</Button>
                        </DialogActions>
                    </form>
                </Dialog>


                <Dialog
                    open={askDeleteModalState.visible}
                    onClose={handleCancelDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Suppression
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Voulez vous supprimer la playlist {askDeleteModalState.playlist?.name}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDelete}>Annuler</Button>
                        <Button onClick={handleConfirmDelete} autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>

            </DragDropContext>
        </>
    );
}