import * as React from 'react';
import {CSSProperties, SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {
    Autocomplete,
    Box,
    IconButton,
    InputAdornment,
    Paper,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {Media} from "../model/media";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import {useMedias, useMediasTags} from "../core/db";
import {List} from "immutable";
import SearchIcon from "@mui/icons-material/Search";
import PreviewIcon from "@mui/icons-material/Preview";
import {MediaModal, useMediaModal} from "./MediaModal";

interface MediaItemProps {
    media: Media,
    handleAddMedia?: (media: Media) => void,
    handleMediaModalClick?: (mediaId: string) => void
}

function MediaItem({media, handleAddMedia, handleMediaModalClick}: MediaItemProps) {

    const onTransferClick = useCallback(() => {
        handleAddMedia?.(media);
    }, [media, handleAddMedia])

    return (
        <Paper sx={{
            width: "100%",
            display: "flex",
            '&:hover .transferButton': {
                visibility: 'visible'
            },
            mt: 1,
            pl: 1,
            pr: 1,
            whiteSpace: 'nowrap',
            ':hover': {
                cursor: 'grab'
            }
        }}>
            <Box sx={{
                flexGrow: 1,
                p: 1,
                overflow: "hidden",
            }} title={media.name}>
                <Typography variant="caption" display="block" gutterBottom sx={{
                    fontWeight: 'bold',
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>
                    {media.name}
                </Typography>

            </Box>
            <Box sx={{
                visibility: 'hidden'
            }}
                 className='transferButton'
            >
                <IconButton
                    aria-label="preview"
                    onClick={() => handleMediaModalClick?.(media.id)}
                >
                    <PreviewIcon/>
                </IconButton>
                <IconButton onClick={onTransferClick}>
                    <ArrowCircleRightIcon/>
                </IconButton>
            </Box>
        </Paper>
    )
}

interface MediaSelectorProps {
    handleAddMedia: (media: Media) => void
}

export default function MediaSelector({handleAddMedia}: MediaSelectorProps) {

    const [medias, mediasLoading, mediasError] = useMedias();

    const [searchStr, setSearchStr] = useState<string | null>(null);
    const [mediasTags] = useMediasTags();

    const onMediaSearchChange = useCallback((event: SyntheticEvent, value: string | null) => {
        setSearchStr(value);
    }, [])

    const mediasToDisplay = useMemo<List<Media>>(() => {
        const search = searchStr ? searchStr.trim().toLowerCase() : '';
        if (search === '') {
            return medias;
        }
        const words = search.split(' ').map((word) => word.trim());

        return medias.filter((media) => {

            const tags = mediasTags.get(media.id);
            const mediaValue = media.name.toLowerCase() + ' ' + (tags ? tags.map((tag) => tag.label.trim().toLowerCase()).join(' ') : '');
            for (let i = 0; i < words.length; i++) {
                if (!mediaValue.includes(words[i])) return false
            }
            return true;
        });
    }, [medias, searchStr, mediasTags])

    const [mediaModalState, handleMediaModalClose, handleMediaModalClick] = useMediaModal();

    return (
        <>

            <Box sx={{
                position: "sticky",
                top: 0,
                height: "100vh",
                minWidth: '20rem',
                maxWidth: '20rem',
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                flexShrink: 0,
                flexGrow: 0
            }}>


                <Droppable droppableId="MEDIAS" isDropDisabled={true}>
                    {(provided, snapshot) => (
                        <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{
                                mt: 9,
                                flexGrow: 1,
                                overflow: "hidden",
                                overflowY: "scroll"
                            }}
                        >
                            <Box sx={{p: 2, pb:0}}>
                                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                    Medias
                                </Typography>
                                <Autocomplete
                                    freeSolo
                                    size='small'
                                    selectOnFocus
                                    id="media-search-input"
                                    disableClearable
                                    options={[].map((option) => '')}
                                    onInputChange={onMediaSearchChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{backgroundColor: 'white'}}
                                            placeholder="Chercher des médias"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Box>


                            <Box sx={{p: 2}}>
                                {mediasToDisplay.map((media, index) => (
                                    <Draggable key={media.id} draggableId={media.id} index={index}>
                                        {(provided, snapshot) => {
                                            const style: CSSProperties = {...provided.draggableProps.style};

                                            if (!snapshot.isDragging) {
                                                delete style.transform;
                                                delete style.transition;
                                            }

                                            if (snapshot.isDragging && snapshot.draggingOver === 'TRACKS') {
                                                return (<TableRow
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    style={style}
                                                >
                                                    <TableCell>{media.name}</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>)
                                            }

                                            return (
                                                <Box>
                                                    <Box
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        style={style}
                                                    >
                                                        <MediaItem media={media} handleAddMedia={handleAddMedia} handleMediaModalClick={handleMediaModalClick}/>
                                                    </Box>
                                                    {snapshot.isDragging && (
                                                        <Box>
                                                            <MediaItem media={media}/>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )
                                        }}
                                    </Draggable>
                                ))}

                            </Box>
                            <Box sx={{display: 'none'}}>
                                {provided.placeholder}
                            </Box>
                        </Box>

                    )}
                </Droppable>
            </Box>

            <MediaModal open={mediaModalState.open} mediaId={mediaModalState.mediaId} handleClose={handleMediaModalClose}/>
        </>
    )
}