import React, {ChangeEvent, CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
    alpha,
    Box, Button,
    ButtonGroup,
    Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
    IconButton, ListItemIcon, ListItemText, Menu,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@mui/material";
import {Playlist, reorderPlaylistTracks, Track} from "../model/playlist";
import {List, Map, Set} from "immutable";
import {DragDropContext, Droppable, Draggable, DropResult, DraggableProvidedDragHandleProps} from "react-beautiful-dnd";
import {ApplicationState} from "../core/playlistsContext";
import DeleteIcon from '@mui/icons-material/Delete';
import Toolbar from "@mui/material/Toolbar";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteTracksModal from "./DeleteTracksModal";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from "@mui/material/MenuItem";
import SquareIcon from '@mui/icons-material/Square';
import {ContentCopy, ContentCut, ContentPaste, DriveFileRenameOutline} from "@mui/icons-material";
import Utils from "../core/utils";
import {Duration} from 'luxon';
import ColorPicker from "./colorPicker";
import {useMedia, usePlaylist, writePlaylist} from "../core/db";
import {MediaModal, useMediaModal} from "./MediaModal";
import PreviewIcon from "@mui/icons-material/Preview";


interface TrackProps {
    track: Track,
    playlistId: string,
    dragHandleProps?: DraggableProvidedDragHandleProps | undefined
    handleTrackMenuClick: (event: React.MouseEvent<HTMLElement>, track: Track) => void,
    handleSelect: (e: ChangeEvent, trackId: string, checked: boolean) => void
    repetition: number,
    handleMediaModalClick: (mediaId: string) => void
}

function Track({
                   track,
                   playlistId,
                   dragHandleProps,
                   handleTrackMenuClick,
                   repetition,
                   handleSelect,
                   handleMediaModalClick
               }: TrackProps) {

    const {
        playlistsState: {selectedTrackIds}
    } = ApplicationState();

    const [media, mediaLoading, mediaError] = useMedia(track.mediaId);

    const isSelected = useMemo(() => {
        return selectedTrackIds.includes(track.id);
    }, [track, selectedTrackIds])

    const onSelect = useCallback((e: ChangeEvent, checked: boolean) => {
        handleSelect(e, track.id, checked)
    }, [track, handleSelect]);

    const onMenuClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        handleTrackMenuClick(e, track);
    }, [handleTrackMenuClick, track])

    const durationStr = useMemo(() => {
        if (!media) return '';
        return Duration.fromObject({milliseconds: media.duration}).toFormat("m:ss")
    }, [media?.duration])

    const totalDurationStr = useMemo(() => {
        if (!media) return '';
        return Duration.fromObject({milliseconds: media.duration * repetition}).toFormat("m:ss")
    }, [media?.duration, repetition])

    return (
        <>
            <TableCell sx={{
                width: "1rem",
                whiteSpace: "nowrap",
                p: 0,
                borderLeft: "1.5rem solid",
                borderLeftColor: track.color || 'white' //TODO get theme color
            }}>
                <Box {...dragHandleProps}>
                    <DragIndicatorIcon/>
                </Box>
            </TableCell>
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isSelected}
                    inputProps={{
                        'aria-labelledby': 'labelId',
                    }}
                    onChange={onSelect}
                />
            </TableCell>
            <TableCell>{media?.name}</TableCell>
            <TableCell>{durationStr}</TableCell>
            <TableCell><Chip label={repetition}/> {totalDurationStr}</TableCell>
            <TableCell
                sx={{
                    width: "1rem",
                    whiteSpace: "nowrap",
                }}
            >
                <ButtonGroup size="small" variant="outlined" aria-label="">
                    <IconButton
                        aria-label="preview"
                        onClick={() => handleMediaModalClick(track.mediaId)}
                    >
                        <PreviewIcon/>
                    </IconButton>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        //aria-controls={open ? 'long-menu' : undefined}
                        //aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={onMenuClick}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                </ButtonGroup>
            </TableCell>
        </>
    )
}

interface TrackListProps {
    trackList: List<Track>,
    playlistId: string
}

export function TrackList({trackList, playlistId}: TrackListProps) {

    const {
        playlistsState: {selectedTrackIds, copyTracksType, copiedTracks, copyTracksOriginPlaylistId},
        playlistsDispatch
    } = ApplicationState();

    const [lastSelectedTrackId, setLastSelectedTrackId] = useState<string | null>(null)
    const [playlist, playlistLoading, playlistError] = usePlaylist(playlistId);


    const isAllChecked = useMemo(() => {
        if (trackList.size === 0) return false;
        return trackList.size === selectedTrackIds.size;
    }, [trackList, selectedTrackIds]);

    const isIndeterminateChecked = useMemo(() => {
        if (trackList.size === 0) return false;
        if (selectedTrackIds.size === 0) return false;
        return trackList.size !== selectedTrackIds.size;
    }, [trackList, selectedTrackIds]);

    const onAllCheckedChange = useCallback((e: ChangeEvent, checked) => {
        if (checked) {
            const trackIds = trackList.map((track) => track.id).toArray();
            playlistsDispatch({type: "ADD_SELECTED_TRACKS", trackIds: trackIds})
        } else {
            playlistsDispatch({type: "CLEAR_SELECTED_TRACKS"})
            setLastSelectedTrackId(null);
        }
    }, [trackList])


    useEffect(() => {
        const toDeselectIds: string[] = [];
        const trackIds = trackList.map((track) => track.id).toArray();
        const selectedTrackIdsArray = selectedTrackIds.toArray();
        for (let i = 0; i < selectedTrackIds.size; i++) {
            if (!trackIds.includes(selectedTrackIdsArray[i])) {
                toDeselectIds.push(selectedTrackIdsArray[i]);
            }
        }
        if (toDeselectIds.length > 0) {
            playlistsDispatch({type: "DELETE_SELECTED_TRACKS", trackIds: toDeselectIds})
        }
    }, [trackList, selectedTrackIds])

    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [toDeleteTrackIds, setToDeleteTrackIds] = useState<string[] | Set<string>>([])


    ////////////////////////////////

    const [trackMenuAnchorEl, setTrackMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const [trackMenuTrackInstance, setTrackMenuTrackInstance] = React.useState<null | Track>(null);
    const trackMenuOpen = Boolean(trackMenuAnchorEl);
    const handleTrackMenuClick = (event: React.MouseEvent<HTMLElement>, track: Track) => {
        setTrackMenuAnchorEl(event.currentTarget);
        setTrackMenuTrackInstance(track)
    };
    const handleTrackMenuClose = () => {
        setTrackMenuAnchorEl(null);
        setTrackMenuTrackInstance(null)
    };


    ////////////////////////////////

    const handleCutClick = useCallback(() => {
        let selectedTracks: List<Track>;
        if (!trackMenuTrackInstance) {
            selectedTracks = trackList.filter((track) => selectedTrackIds.includes(track.id));
        } else {
            selectedTracks = List([trackMenuTrackInstance])
        }
        playlistsDispatch({type: "CUT_TRACKS", playlistId: playlistId, tracks: selectedTracks})
    }, [playlistId, trackList, selectedTrackIds, trackMenuTrackInstance])

    const handleCopyClick = useCallback(() => {
        let selectedTracks: List<Track>;
        if (!trackMenuTrackInstance) {
            selectedTracks = trackList.filter((track) => selectedTrackIds.includes(track.id));
        } else {
            selectedTracks = List([trackMenuTrackInstance])
        }
        playlistsDispatch({type: "COPY_TRACKS", playlistId: playlistId, tracks: selectedTracks})
    }, [playlistId, trackList, selectedTrackIds, trackMenuTrackInstance])

    const handlePasteClick = useCallback((e: React.MouseEvent, position?: number) => {
        if (!playlist) return;

        if (copyTracksType === 'COPY') {
            const newTracks = copiedTracks.map((track) => {
                return track.merge({
                    id: Utils.uuid()
                });
            })

            const tracks = newTracks.reduce<List<Track>>((trackList, track) => {
                const insertPosition = position !== undefined ? position : trackList.size;
                return trackList.insert(insertPosition, track);
            }, playlist.tracks);
            writePlaylist(playlist.set('tracks', tracks)).catch(console.error);
        }
        if (copyTracksType === 'CUT') {
            if (copyTracksOriginPlaylistId === playlistId) {
                let trackIds = trackList.map((track) => track.id);
                const copiedTrackIds = copiedTracks.map((track) => track.id);
                trackIds = trackIds.filter((trackId) => !copiedTrackIds.includes(trackId))
                if (position === undefined) {
                    trackIds = trackIds.concat(copiedTrackIds);
                } else {
                    const start = trackIds.slice(0, position);
                    const end = trackIds.slice(position);
                    trackIds = start.concat(copiedTrackIds).concat(end);
                }
                writePlaylist(reorderPlaylistTracks(playlist, trackIds.toArray())).catch(console.error);
                playlistsDispatch({type: "CLEAR_CUT_DATA"})
            }
        }
    }, [playlist, trackList, copyTracksType, copiedTracks, copyTracksOriginPlaylistId])

    const handlePasteBeforeClick = useCallback((e: React.MouseEvent) => {
        if (!trackMenuTrackInstance) return;
        let trackListWithoutCut = trackList;
        const copiedTrackIds = copiedTracks.map((track) => track.id);
        if (copyTracksType === 'CUT') {
            trackListWithoutCut = trackList.filter((track) => !copiedTrackIds.includes(track.id))
        }
        const position = trackListWithoutCut.indexOf(trackMenuTrackInstance);
        handlePasteClick(e, position);
    }, [trackList, handlePasteClick, trackMenuTrackInstance, copyTracksType]);

    const handlePasteAfterClick = useCallback((e: React.MouseEvent) => {
        if (!trackMenuTrackInstance) return;

        let trackListWithoutCut = trackList;
        const copiedTrackIds = copiedTracks.map((track) => track.id);
        if (copyTracksType === 'CUT') {
            trackListWithoutCut = trackList.filter((track) => !copiedTrackIds.includes(track.id))
        }
        const position = trackListWithoutCut.indexOf(trackMenuTrackInstance);
        handlePasteClick(e, position + 1);
    }, [trackList, handlePasteClick, trackMenuTrackInstance, copyTracksType])


    const handleDeleteTracksClick = useCallback((event: React.MouseEvent, trackIds?: string[] | Set<string>) => {
        if (trackIds === undefined) trackIds = selectedTrackIds.toArray();
        if (Set.isSet(trackIds)) trackIds = trackIds.toArray();

        if (trackMenuTrackInstance) {
            trackIds = [trackMenuTrackInstance.id]
        }

        if (trackIds.length > 0) {
            setToDeleteTrackIds(trackIds);
            setDeleteModalVisible(true)
        }
    }, [selectedTrackIds, trackMenuTrackInstance]);

    const colors = [
        '#FFFFFF',
        '#444444',
        '#bf8d31',
        '#00CA4E',
        '#34568B',
        '#ba4581',
        '#F7CAC9',
        '#92A8D1',
        '#ffda40',
        '#ff5151'
    ]

    const handleColorClick = useCallback((e: React.MouseEvent, color: string) => {
        let trackIds: string[];
        if (trackMenuTrackInstance) {
            trackIds = [trackMenuTrackInstance.id];
        } else {
            trackIds = selectedTrackIds.toArray();
        }
        if (trackIds.length > 0 && playlist) {
            const tracks = playlist.tracks.map((track) => {
                if (trackIds.includes(track.id)) {
                    track = track.set('color', color);
                }
                return track;
            });
            writePlaylist(playlist.set('tracks', tracks)).catch(console.error)
        }
    }, [trackMenuTrackInstance, playlist, selectedTrackIds])


    const uniqTrackCounterIndex = useMemo(() => {
        return trackList.reduce((result, track) => {
            const count = result.get(track.mediaId, 0);
            return result.set(track.mediaId, count + 1);
        }, Map<string, number>())
    }, [trackList])


    ////////////////////////////

    const globalColorPickerButtonEl = useRef<HTMLButtonElement | null>(null)
    const [colorMenuOpen, setColorMenuOpen] = useState(false);
    const handleColorMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setColorMenuOpen(true)
    };
    const handleColorMenuClose = () => {
        setColorMenuOpen(false)
    };

    /////////////////////


    const handleSelect = useCallback((e: ChangeEvent, trackId: string, checked: boolean) => {
        let isShift = false;
        if (e.nativeEvent instanceof PointerEvent) {
            isShift = e.nativeEvent.shiftKey;
        }
        if (checked) {
            if (!isShift || lastSelectedTrackId === null) {
                setLastSelectedTrackId(trackId)
                playlistsDispatch({type: "ADD_SELECTED_TRACKS", trackIds: [trackId]})
            }

            if (isShift && lastSelectedTrackId !== null) {
                const trackIds = trackList.map((track) => track.id);

                const selectPosition = trackIds.indexOf(trackId);
                const lastPosition = trackIds.indexOf(lastSelectedTrackId);

                const start = Math.min(selectPosition, lastPosition);
                const end = Math.max(selectPosition, lastPosition);
                if (start === -1) {
                    setLastSelectedTrackId(trackId)
                    playlistsDispatch({type: "ADD_SELECTED_TRACKS", trackIds: [trackId]})
                } else {
                    const toSelectIds = trackIds.slice(start, end).concat([trackId, lastSelectedTrackId])
                    playlistsDispatch({type: "CLEAR_SELECTED_TRACKS"})
                    playlistsDispatch({type: "ADD_SELECTED_TRACKS", trackIds: toSelectIds.toArray()})
                }
            }
        }
        if (!checked) playlistsDispatch({type: "DELETE_SELECTED_TRACKS", trackIds: [trackId]})
    }, [lastSelectedTrackId, trackList]);


    const [mediaModalState, handleMediaModalClose, handleMediaModalClick] = useMediaModal();

    return (<Box>
        <Box sx={{display: 'flex'}}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Pistes
            </Typography>
            <Box
                sx={{
                    flexGrow: 1,
                    pl: {sm: 2},
                    pr: {xs: 1, sm: 1},
                    minHeight: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                    gap: 2
                }}
            >
                <Typography
                    sx={{
                        visibility: selectedTrackIds.size > 0 ? 'visible' : 'hidden'
                    }}
                    color="inherit"
                    variant="subtitle1"
                    component="span"
                >
                    Pistes selectionnées : {selectedTrackIds.size}
                </Typography>

                <Box>

                    <Tooltip title="Sélectioner la couleur de la sélection">
                        <span>
                            <IconButton
                                disabled={selectedTrackIds.size === 0}
                                ref={globalColorPickerButtonEl}
                                onClick={handleColorMenuClick}
                            >
                                <SquareIcon
                                    sx={{
                                        boxShadow: "0px 0px 0px 2px #cccccc"
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Tooltip title="Couper la selection">
                        <span>
                            <IconButton disabled={selectedTrackIds.size === 0} onClick={() => handleCutClick()}>
                                <ContentCut/>
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Tooltip title="Copier la selection">
                        <span>
                            <IconButton disabled={selectedTrackIds.size === 0} onClick={() => handleCopyClick()}>
                                <ContentCopy/>
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Coller">
                        <span>
                            <IconButton disabled={copyTracksType === null} onClick={handlePasteClick}>
                                <ContentPaste/>
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Supprimer la selection">
                        <span>
                            <IconButton disabled={selectedTrackIds.size === 0} onClick={handleDeleteTracksClick}>
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>

            </Box>
        </Box>
        <Droppable droppableId="TRACKS">
            {(provided, snapshot) => {

                return (
                    <TableContainer
                        sx={{overflow: 'hidden'}}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <Table
                            sx={{minWidth: 750}}
                            aria-labelledby="tableTitle"
                            size={'small'}
                        >
                            <TableHead>
                                <TableRow sx={{
                                    //backgroundColor: "rgba(0,0,0,0.04)" // TODO select color
                                }}>
                                    <TableCell>

                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={isIndeterminateChecked}
                                            checked={isAllChecked}
                                            onChange={onAllCheckedChange}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <strong>Nom</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Durée</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Répétition</strong>
                                    </TableCell>
                                    <TableCell>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trackList.map((track, index) => (
                                    <Draggable key={track.id} draggableId={track.id} index={index}>
                                        {(provided, snapshot) => {
                                            const style: CSSProperties = {...provided.draggableProps.style}

                                            // TODO GROS TODO, parse translate
                                            if (style.transform) {
                                                let y = 0;
                                                // @ts-ignore
                                                y = CSSStyleValue.parse("transform", style.transform)[0].y.value;
                                                style.transform = `translateY(${y}px)`
                                            }


                                            return (
                                                <TableRow
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    sx={{
                                                        backgroundColor: 'white'
                                                    }}
                                                    style={style}
                                                    selected={selectedTrackIds.includes(track.id)}
                                                    hover
                                                >
                                                    <Track
                                                        track={track}
                                                        playlistId={playlistId}
                                                        dragHandleProps={provided.dragHandleProps}
                                                        handleTrackMenuClick={handleTrackMenuClick}
                                                        handleSelect={handleSelect}
                                                        repetition={uniqTrackCounterIndex.get(track.mediaId, 0)}
                                                        handleMediaModalClick={handleMediaModalClick}
                                                    />
                                                </TableRow>

                                            )
                                        }}
                                    </Draggable>)
                                )}
                                {snapshot.isUsingPlaceholder && (
                                    <TableRow sx={{visibility: 'hidden'}}>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                )}
                                <TableRow sx={{display: 'none'}}>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }}
        </Droppable>
        <DeleteTracksModal
            visible={deleteModalVisible}
            playlistId={playlistId}
            trackIds={toDeleteTrackIds}
            onFinish={() => setDeleteModalVisible(false)}
        />


        <Menu
            MenuListProps={{
                'aria-labelledby': 'color-picker',
            }}
            anchorEl={globalColorPickerButtonEl.current}
            open={colorMenuOpen}
            onClose={handleColorMenuClose}
            onClick={handleColorMenuClose}
        >
            <ColorPicker colors={colors} handleColorClick={handleColorClick}/>
        </Menu>

        <Menu
            MenuListProps={{
                'aria-labelledby': 'track-options',
            }}
            anchorEl={trackMenuAnchorEl}
            open={trackMenuOpen}
            onClose={handleTrackMenuClose}
            onClick={handleTrackMenuClose}
        >

            <ColorPicker colors={colors} handleColorClick={handleColorClick}/>

            <Divider/>
            <MenuItem onClick={handleCutClick}>
                <ListItemIcon>
                    <ContentCut fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Couper la piste</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleCopyClick}>
                <ListItemIcon>
                    <ContentCopy fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Copier la piste</ListItemText>
            </MenuItem>
            <MenuItem disabled={copyTracksType === null} onClick={handlePasteBeforeClick}>
                <ListItemIcon>
                    <ContentPaste fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Coller avant</ListItemText>
            </MenuItem>
            <MenuItem disabled={copyTracksType === null} onClick={handlePasteAfterClick}>
                <ListItemIcon>
                    <ContentPaste fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Coller après</ListItemText>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={handleDeleteTracksClick}>
                <ListItemIcon>
                    <DeleteIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Supprimer la piste</ListItemText>
            </MenuItem>
        </Menu>

        <MediaModal open={mediaModalState.open} mediaId={mediaModalState.mediaId} handleClose={handleMediaModalClose}/>

    </Box>)
}