import React, {useCallback} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Set} from "immutable";
import {ApplicationState} from "../core/playlistsContext";
import {usePlaylist, writePlaylist} from "../core/db";


interface DeleteTracksModalProps {
    visible: boolean,
    trackIds: string[] | Set<string>,
    playlistId: string | null,
    onFinish?: () => void
}

export default function DeleteTracksModal({visible, trackIds, playlistId, onFinish}: DeleteTracksModalProps) {

    const [playlist] = usePlaylist(playlistId);

    const handleConfirm = useCallback(() => {
        const toDeleteTrackIds = Set.isSet(trackIds) ? trackIds.toArray() : trackIds;
        if (playlist) {
            const tracks = playlist.tracks.filter((track) => toDeleteTrackIds.indexOf(track.id) === -1)
            writePlaylist(playlist.set('tracks', tracks)).catch(console.error);
        }
        onFinish?.()
    }, [trackIds, playlist, onFinish]);

    const handleCancel = useCallback(() => {
        onFinish?.()
    }, []);

    return (
        <Dialog
            open={visible}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Suppression
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Voulez vous supprimer la piste?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Annuler</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Supprimer
                </Button>
            </DialogActions>
        </Dialog>
    )
}