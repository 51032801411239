import {useReducer} from "react";
import {List, Record, RecordOf, Set} from "immutable";


export type MediasAction =
    { type: 'SET_SELECTED_MEDIAS', mediaIds: string[] }
    | { type: 'ADD_SELECTED_MEDIAS', mediaIds: string[] }
    | { type: 'DELETE_SELECTED_MEDIAS', mediaIds: string[] }
    | { type: 'CLEAR_SELECTED_MEDIAS'}


interface IMediasState {
    selectedMediaIds: Set<string>
}

export type MediasState = RecordOf<IMediasState>;
const mediaStateFactory = Record<IMediasState>({
    selectedMediaIds: Set(),
})

const defaultMediasState: MediasState = mediaStateFactory();

export const mediasReducer = (state: MediasState, action: MediasAction): MediasState => {
    switch (action.type) {
        case "SET_SELECTED_MEDIAS": {
            return state.set('selectedMediaIds', Set(action.mediaIds));
        }
        case "ADD_SELECTED_MEDIAS": {
            const selectedTrackIds = state.selectedMediaIds.union(action.mediaIds);
            return state.set('selectedMediaIds', selectedTrackIds);
        }
        case "DELETE_SELECTED_MEDIAS": {
            const selectedTrackIds = state.selectedMediaIds.subtract(action.mediaIds);
            return state.set('selectedMediaIds', selectedTrackIds);
        }
        case "CLEAR_SELECTED_MEDIAS": {
            return state.set('selectedMediaIds', Set());
        }
        default:
            return state;
    }
};

export const getMediasReducer = () => useReducer(mediasReducer, defaultMediasState);