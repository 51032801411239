import React from 'react';
import {Box, IconButton} from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";
import MenuItem from "@mui/material/MenuItem";

interface ColorPickerProps {
    colors: string[],
    handleColorClick: (e: React.MouseEvent, color: string) => void
}

export default function ColorPicker({colors, handleColorClick}: ColorPickerProps) {
    return (
        <MenuItem disableRipple
                  sx={{
                      '&:hover': {
                          backgroundColor: 'transparent'
                      }
                  }}

        >
            <Box>
                {colors.map((color, index) => (
                    <React.Fragment key={color}>
                        <IconButton
                            sx={{
                                color
                            }}
                            onClick={(e) => handleColorClick(e, color)}
                        >
                            <SquareIcon
                                sx={{
                                    boxShadow: "0px 0px 0px 2px #cccccc"
                                }}
                            />
                        </IconButton>
                        {index === 4 && <br/>}
                    </React.Fragment>
                ))}
            </Box>
        </MenuItem>
    )
}