import {useReducer} from "react";
import {List, Record, RecordOf, Set} from "immutable";
import {Track} from "../model/playlist";

export type PlaylistsAction =
    { type: 'SELECT_PLAYLIST', playlistId: string | null }
    | { type: 'SET_SELECTED_TRACKS', trackIds: string[] }
    | { type: 'ADD_SELECTED_TRACKS', trackIds: string[] }
    | { type: 'DELETE_SELECTED_TRACKS', trackIds: string[] }
    | { type: 'CLEAR_SELECTED_TRACKS'}
    | { type: 'CUT_TRACKS', tracks: List<Track>, playlistId: string }
    | { type: 'COPY_TRACKS', tracks: List<Track>, playlistId: string }
    | { type: 'CLEAR_CUT_DATA' }


interface IPlaylistsState {
    selectedPlaylistId: string | null,
    selectedTrackIds: Set<string>,
    copiedTracks: List<Track>,
    copyTracksOriginPlaylistId: string | null;
    copyTracksType: 'CUT' | 'COPY' | null
}

export type PlaylistsState = RecordOf<IPlaylistsState>;
const playlistsStateFactory = Record<IPlaylistsState>({
    selectedPlaylistId: null,
    selectedTrackIds: Set(),
    copiedTracks: List(),
    copyTracksType: null,
    copyTracksOriginPlaylistId: null
})

const defaultPlaylistState: PlaylistsState = playlistsStateFactory();

export const playlistsReducer = (state: PlaylistsState, action: PlaylistsAction): PlaylistsState => {
    switch (action.type) {
        case "SELECT_PLAYLIST": {
            state = state.set('selectedPlaylistId', action.playlistId);
            return state.merge({
                selectedTrackIds: Set(),
                copyTracksType: null,
                copiedTracks: List(),
                copyTracksOriginPlaylistId: null
            })

        }
        case "SET_SELECTED_TRACKS": {
            return state.set('selectedTrackIds', Set(action.trackIds));
        }
        case "ADD_SELECTED_TRACKS": {
            const selectedTrackIds = state.selectedTrackIds.union(action.trackIds);
            return state.set('selectedTrackIds', selectedTrackIds);
        }
        case "DELETE_SELECTED_TRACKS": {
            const selectedTrackIds = state.selectedTrackIds.subtract(action.trackIds);
            return state.set('selectedTrackIds', selectedTrackIds);
        }
        case "CLEAR_SELECTED_TRACKS": {
            return state.set('selectedTrackIds', Set());
        }
        case "CUT_TRACKS": {
            if(action.tracks.size === 0) return state;
            return state.merge({
                copyTracksType: 'CUT',
                copiedTracks: action.tracks,
                copyTracksOriginPlaylistId: action.playlistId
            })
        }
        case "COPY_TRACKS": {
            if(action.tracks.size === 0) return state;
            return state.merge({
                copyTracksType: 'COPY',
                copiedTracks: action.tracks,
                copyTracksOriginPlaylistId: action.playlistId
            })
        }
        case "CLEAR_CUT_DATA": {
            return state.merge({
                copyTracksType: null,
                copiedTracks: List(),
                copyTracksOriginPlaylistId: null
            })
        }
        default:
            return state;
    }
};

export const getPlaylistReducer = () => useReducer(playlistsReducer, defaultPlaylistState);