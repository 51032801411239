import React from "react";


import {Box, Grid, Paper, styled} from "@mui/material";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import PlayListsPage from "./pages/playlists";
import BroadcastPage from "./pages/broadcast";
import MediasListPage from "./pages/mediasList";
import Context from "./core/playlistsContext";


const Item = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function App() {
    return (
        <Context>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MediasListPage/>}/>
                    <Route path="playlists" element={<PlayListsPage/>}/>
                    <Route path="broadcast" element={<BroadcastPage/>}/>
                </Routes>
            </BrowserRouter>
        </Context>
    );
}

function createBrowserHistory() {
    throw new Error("Function not implemented.");
}
