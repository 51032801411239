import React from 'react'
import {VncScreen} from 'react-vnc';

interface LiveProps {
    host: string
}

export default function Live({host}: LiveProps) {

    return (<VncScreen
        url={host}
        scaleViewport
        //background="#000000"
        viewOnly={true}
        showDotCursor={false}
        style={{
            width: '100%',
            height: '100%',
        }}
    />)

}