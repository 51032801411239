import React, {ChangeEvent, ChangeEventHandler, SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react'
import ResponsiveAppBar from "../components/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    Checkbox, Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton, Input, InputAdornment,
    ListItemIcon,
    ListItemText,
    Menu,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {ApplicationState} from "../core/playlistsContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import {ContentCopy, ContentCut, ContentPaste, DriveFileRenameOutline} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {Media, mediaFactory, Tag, tagFactory} from "../model/media";
import {useForm} from "react-hook-form";
import Utils from "../core/utils";
import {Duration} from "luxon";
import {uploadFile} from "../core/firebase";
import {deleteMedias, useMedias, useMediasTags, useTags, writeMedia, writeTag} from "../core/db";
import {MediaTagsEditor} from "../components/MediaTags";
import {MediaModal, useMediaModal} from "../components/MediaModal";
import PreviewIcon from '@mui/icons-material/Preview';
import SearchIcon from '@mui/icons-material/Search';
import {List} from 'immutable';
import Upload from "../components/Upload";

type AskNameFormData = {
    askMediaNameInput: string;
};


export default function MediasListPage() {

    const {
        mediasDispatch,
        mediasState: {selectedMediaIds}
    } = ApplicationState();


    const [medias, mediasLoading, mediasError, mediasIndex] = useMedias();
    const [mediasTags] = useMediasTags();

    const [lastSelectedMediaId, setLastSelectedMediaId] = useState<string | null>(null)
    const handleSelect = useCallback((e: ChangeEvent, mediaId: string, checked: boolean) => {
        let isShift = false;
        if (e.nativeEvent instanceof PointerEvent) {
            isShift = e.nativeEvent.shiftKey;
        }
        if (checked) {
            if (!isShift || lastSelectedMediaId === null) {
                setLastSelectedMediaId(mediaId)
                mediasDispatch({type: "ADD_SELECTED_MEDIAS", mediaIds: [mediaId]})
            }

            if (isShift && lastSelectedMediaId !== null) {
                const mediaIds = medias.map((media) => media.id);

                const selectPosition = mediaIds.indexOf(mediaId);
                const lastPosition = mediaIds.indexOf(lastSelectedMediaId);

                const start = Math.min(selectPosition, lastPosition);
                const end = Math.max(selectPosition, lastPosition);

                if (start === -1) {
                    setLastSelectedMediaId(mediaId)
                    mediasDispatch({type: "ADD_SELECTED_MEDIAS", mediaIds: [mediaId]})
                } else {
                    const toSelectIds = mediaIds.slice(start, end).concat([mediaId, lastSelectedMediaId])
                    mediasDispatch({type: "CLEAR_SELECTED_MEDIAS"})
                    mediasDispatch({type: "ADD_SELECTED_MEDIAS", mediaIds: toSelectIds.toArray()})
                }
            }
        }
        if (!checked) mediasDispatch({type: "DELETE_SELECTED_MEDIAS", mediaIds: [mediaId]})
    }, [lastSelectedMediaId, medias]);


    ////////////////////////////////////////

    const [mediaMenuAnchorEl, setMediaMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mediaMenuMediaInstance, setMediaMenuMediaInstance] = React.useState<null | Media>(null);
    const mediaMenuOpen = Boolean(mediaMenuAnchorEl);
    const handleMediaMenuClick = (event: React.MouseEvent<HTMLElement>, media: Media) => {
        setMediaMenuAnchorEl(event.currentTarget);
        setMediaMenuMediaInstance(media)
    };
    const handleMediaMenuClose = () => {
        setMediaMenuAnchorEl(null);
        setMediaMenuMediaInstance(null)
    };

    ///////////////////////////////////////

    const [askDeleteModalState, setAskDeleteModalState] = React.useState({
        visible: false,
        medias: null as List<Media> | null
    });

    const handleConfirmDelete = useCallback(() => {
        if (askDeleteModalState.medias) {
            deleteMedias(askDeleteModalState.medias).catch(console.error)
            const toDeleteMediaIds = askDeleteModalState.medias.map((media) => media.id)
            const mediaIds = selectedMediaIds.filter((selectedMediaId) => !toDeleteMediaIds.includes(selectedMediaId))
            mediasDispatch({type: "SET_SELECTED_MEDIAS", mediaIds: mediaIds.toArray()})
            setLastSelectedMediaId(null);
        }
        setAskDeleteModalState({
            visible: false,
            medias: null
        });
    }, [askDeleteModalState, selectedMediaIds]);

    const handleCancelDelete = useCallback(() => {
        setAskDeleteModalState({
            visible: false,
            medias: null
        });
    }, []);

    const handleDeleteClick = useCallback((fromSelection?: boolean) => {
        if (fromSelection) {
            let medias = selectedMediaIds.map((mediaId) => mediasIndex.get(mediaId)).toList();
            const notNullMedias = medias.filter((media) => !!media) as List<Media>
            if (medias.size > 0) {
                setAskDeleteModalState({
                    visible: true,
                    medias: notNullMedias
                })
            }
        } else if (mediaMenuMediaInstance) {
            setAskDeleteModalState({
                visible: true,
                medias: List([mediaMenuMediaInstance])
            })
        }
    }, [mediaMenuMediaInstance, selectedMediaIds])

    /////////////////////////////////////

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
        setValue: setAskFormValue,
        setFocus: setAskFormFocus
    } = useForm<AskNameFormData>();

    const {ref, ...registerParams} = register("askMediaNameInput", {required: true, minLength: 1})
    const [askNameState, setAskNameState] = React.useState({
        visible: false,
        media: null as Media | null,
        onConfirm: null as ((name: string) => void) | null,
    });

    const onAskNameCancel = useCallback(() => {
        setAskNameState({visible: false, media: null, onConfirm: null});
    }, [])

    const onAskNameSubmit = useCallback((data: AskNameFormData) => {
        askNameState.onConfirm?.(data.askMediaNameInput)
        setAskNameState({visible: false, media: null, onConfirm: null});
    }, [askNameState])

    const handleRenameClick = useCallback(() => {
        if (!mediaMenuMediaInstance) return;
        setAskFormValue("askMediaNameInput", mediaMenuMediaInstance.name)
        setAskNameState({
            visible: true,
            media: mediaMenuMediaInstance,
            onConfirm: (name) => {
                writeMedia(mediaMenuMediaInstance.merge({
                    name
                })).catch(console.error)
            }
        })
    }, [mediaMenuMediaInstance])

    const [files, setFiles] = useState<File[]>([])

    const handleFileUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        console.log('upload');
        if (!e.target.files) return;

        setFiles(files.concat(Array.from(e.target.files)));
    }, [files])

    const handleFileComplete = useCallback((completeFile: File) => {
        setFiles((files) => {
            return files.filter((file) => completeFile === file)
        });
    }, [])

    const [mediaModalState, handleMediaModalClose, handleMediaModalClick] = useMediaModal();

    const isAllChecked = useMemo(() => {
        if (medias.size === 0) return false;
        return medias.size === selectedMediaIds.size;
    }, [medias, selectedMediaIds]);

    const isIndeterminateChecked = useMemo(() => {
        if (medias.size === 0) return false;
        if (selectedMediaIds.size === 0) return false;
        return medias.size !== selectedMediaIds.size;
    }, [medias, selectedMediaIds]);

    //TODO bug after delete media
    const onAllCheckedChange = useCallback((e: ChangeEvent, checked) => {
        if (checked) {
            const mediaIds = medias.map((media) => media.id).toArray();
            mediasDispatch({type: "ADD_SELECTED_MEDIAS", mediaIds})
        } else {
            mediasDispatch({type: "CLEAR_SELECTED_MEDIAS"})
            setLastSelectedMediaId(null);
        }
    }, [medias])

    const [searchStr, setSearchStr] = useState<string | null>(null);

    const onMediaSearchChange = useCallback((event: SyntheticEvent, value: string | null) => {
        setSearchStr(value);
    }, [])

    const mediasToDisplay = useMemo<List<Media>>(() => {
        const search = searchStr ? searchStr.trim().toLowerCase() : '';
        if (search === '') {
            return medias;
        }
        const words = search.split(' ').map((word) => word.trim());

        return medias.filter((media) => {

            const tags = mediasTags.get(media.id);
            const mediaValue = media.name.toLowerCase() + ' ' + (tags ? tags.map((tag) => tag.label.trim().toLowerCase()).join(' ') : '');
            for (let i = 0; i < words.length; i++) {
                if (!mediaValue.includes(words[i])) return false
            }
            return true;
        });
    }, [medias, searchStr, mediasTags])

    return (
        <>
            <Box sx={{display: 'flex'}}>
                <ResponsiveAppBar/>
                <Container sx={{p: 3, flexGrow: 1, backgroundColor: '#f5f5f5', minHeight: '100vh'}}>
                    <Toolbar/>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                    }}>
                        <Typography variant="h4">
                            Medias
                        </Typography>
                        <Box sx={{flexGrow: 1, pl: 3}}>
                            <Autocomplete
                                freeSolo
                                selectOnFocus
                                size='small'
                                id="media-search-input"
                                disableClearable
                                options={[].map((option) => '')}
                                onInputChange={onMediaSearchChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{backgroundColor: 'white'}}
                                        placeholder="Chercher des médias"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        <Box sx={{textAlign: 'right'}}>
                            <label htmlFor="contained-button-file">
                                <input onChange={handleFileUpload} value={[]} style={{display: 'none'}}
                                       accept="video/mp4"
                                       id="contained-button-file" multiple type="file"/>
                                <Button variant="contained" component="span">
                                    Ajouter
                                </Button>
                            </label>
                        </Box>
                    </Box>


                    <Upload files={files} handleFileComplete={handleFileComplete}/>
                    <Paper
                        sx={{
                            mt: 2,
                            mb: 2,
                            p: 2,
                        }}
                    >
                        <Box sx={{display: 'flex'}}>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                Medias
                            </Typography>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    pl: {sm: 2},
                                    pr: {xs: 1, sm: 1},
                                    minHeight: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'right',
                                    gap: 1
                                }}
                            >
                                <Typography
                                    sx={{
                                        visibility: selectedMediaIds.size > 0 ? 'visible' : 'hidden'
                                    }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="span"
                                >
                                    Média(s) selectionné(s) : {selectedMediaIds.size}
                                </Typography>

                                <Tooltip title="Supprimer la selection">
                                    <span>
                                        <IconButton disabled={selectedMediaIds.size === 0}
                                                    onClick={() => handleDeleteClick(true)}
                                        >
                                            <DeleteIcon/>
                                            </IconButton>
                                    </span>
                                </Tooltip>

                            </Box>
                        </Box>
                        <TableContainer>
                            <Table
                                sx={{minWidth: 750}}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                            >

                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={isIndeterminateChecked}
                                                checked={isAllChecked}
                                                onChange={onAllCheckedChange}
                                                color="primary"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <strong>Nom</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>Tags</strong>
                                        </TableCell>
                                        <TableCell sx={{whiteSpace: 'nowrap'}}>
                                            <strong>Date de création</strong>
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mediasToDisplay.map((media, index) => {
                                        const isSelected = selectedMediaIds.includes(media.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        const durationStr = Duration.fromObject({milliseconds: media.duration}).toFormat("m:ss")

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={media.id}
                                                selected={isSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                        onChange={(e, checked) => handleSelect(e, media.id, checked)}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    <Typography sx={{whiteSpace: "nowrap"}}>
                                                        <Typography component="span" sx={{fontWeight: 'bold'}}>
                                                            {media.name}
                                                        </Typography>
                                                        <Typography color="text.info" component="span"
                                                                    sx={{fontWeight: 'regular'}}>
                                                            &nbsp; ({durationStr})
                                                        </Typography>

                                                    </Typography>
                                                    <Typography color="text.secondary" sx={{fontWeight: 'light'}}
                                                                variant='caption'>
                                                        {media.filename}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    sx={{width: '100%'}}
                                                >
                                                    {/*
                                                        <Box>
                                                            {media.tags.map((tag) => {
                                                                return (<Chip key={tag.label} label={tag.label}
                                                                              size="small"/>)
                                                            })}
                                                        </Box>
                                                    */}

                                                    <MediaTagsEditor media={media}/>
                                                </TableCell>
                                                <TableCell
                                                    sx={{whiteSpace: 'nowrap'}}
                                                    align="right">{media.creationDate.toLocaleString()}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        width: "1rem",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    <ButtonGroup size="small" variant="outlined" aria-label="">
                                                        <IconButton
                                                            aria-label="preview"
                                                            onClick={() => handleMediaModalClick(media.id)}
                                                        >
                                                            <PreviewIcon/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            //aria-controls={open ? 'long-menu' : undefined}
                                                            //aria-expanded={open ? 'true' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={(e) => handleMediaMenuClick(e, media)}
                                                        >
                                                            <MoreVertIcon/>
                                                        </IconButton>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container>
            </Box>

            <Menu
                MenuListProps={{
                    'aria-labelledby': 'track-options',
                }}
                anchorEl={mediaMenuAnchorEl}
                open={mediaMenuOpen}
                onClose={handleMediaMenuClose}
                onClick={handleMediaMenuClose}
            >

                <MenuItem
                    onClick={handleRenameClick}
                >
                    <ListItemIcon>
                        <DriveFileRenameOutline fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Renommer le media</ListItemText>
                </MenuItem>
                <Divider/>
                <MenuItem
                    onClick={() => handleDeleteClick()}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Supprimer le media</ListItemText>
                </MenuItem>
            </Menu>

            <Dialog
                open={askNameState.visible}
                onClose={onAskNameCancel}
                maxWidth="md"
                fullWidth={true}
            >
                <form onSubmit={handleSubmit(onAskNameSubmit)}>
                    <DialogTitle>Nom du media</DialogTitle>
                    <DialogContent>
                        <TextField
                            {...registerParams}
                            ref={(e) => {
                                ref(e)
                                e?.getElementsByTagName('input')[0]?.select();
                            }}
                            autoFocus
                            margin="dense"
                            size="medium"
                            name="askMediaNameInput"
                            id="askMediaNameInput"
                            label="Nom du media"
                            type="text"
                            fullWidth
                            variant="standard"
                            autoComplete="off"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onAskNameCancel}>Annuler</Button>
                        <Button type="submit">OK</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={askDeleteModalState.visible}
                onClose={handleCancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Suppression
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Voulez vous supprimer le media
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>Annuler</Button>
                    <Button onClick={handleConfirmDelete} autoFocus>
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>

            <MediaModal open={mediaModalState.open} mediaId={mediaModalState.mediaId}
                        handleClose={handleMediaModalClose}/>

        </>
    );
}