import React, {useMemo} from 'react';
import {Autocomplete, Box, createFilterOptions, IconButton, TextField} from "@mui/material";
import {Media, Tag, tagFactory} from "../model/media";
import Utils from "../core/utils";
import {List} from "immutable";
import {useMediaTags, useTags, writeMediaTags, writeTag} from "../core/db";

interface ColorPickerProps {
    media: Media
}

interface TagOption {
    tag: Tag,
    inputValue?: string;
}

const tagsFilter = createFilterOptions<TagOption>();


export function useTagsOptions(): [TagOption[], boolean, Error | undefined] {
    const [dbTags, tagsLoading, tagsError] = useTags();

    const tagsOptionList: TagOption[] = useMemo(() => {
        if (!dbTags) return [];
        const foundLabels : string[] = [];
        return dbTags.map((tag) => {
            const val = tag.val()
            const label = val.label.toLowerCase();
            if(foundLabels.includes(label)) return null;
            foundLabels.push(label);
            return {tag: tagFactory().merge(val)}
        }).filter((tag) => tag !== null) as TagOption[];
    }, [dbTags])
    return [tagsOptionList, tagsLoading, tagsError]
}


export function MediaTagsEditor({media}: ColorPickerProps) {
    const [tagsOptionList, tagsLoading, tagsError] = useTagsOptions();
    const [mediaTags, mediaTagsLoading, mediaTagsError] = useMediaTags(media)

    const tagOptions: TagOption[] = useMemo(() => {
        return mediaTags.map((tag) => ({tag})).toArray();
    }, [mediaTags])

    return (
        <Autocomplete
            multiple
            size="small"
            id="tags-outlined"
            value={tagOptions}
            options={tagsOptionList}
            disableClearable
            onChange={(event, newValues) => {
                const tagValues: TagOption[] = newValues.map((newValue) => {
                    if (typeof newValue === 'string') {
                        return {
                            tag: tagFactory({
                                label: newValue,
                                id: Utils.uuid()
                            })
                        }
                    }
                    if (newValue.inputValue) {
                        return {
                            tag: tagFactory({
                                label: newValue.inputValue,
                                id: Utils.uuid()
                            })
                        }
                    }
                    return newValue
                });

                const tagList = List(tagValues.map((tagOption) => tagOption.tag));
                writeMediaTags(media, tagList).catch(console.error);

                tagList.forEach((tag) => {
                    writeTag(tag).catch((e) => {
                        console.error(e)
                    })
                })
            }}
            filterOptions={(options: TagOption[], params) => {
                const filtered = tagsFilter(options, params);

                const {inputValue} = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.tag.label);

                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        tag: tagFactory({
                            id: 'ToAdd',
                            label: `Ajouter "${inputValue}"`,
                        }),
                        inputValue
                    });
                }
                return filtered;
            }}
            isOptionEqualToValue={(option, value) => {
                if (typeof option === 'string') return false;
                return option.tag.id === value.tag.id
            }}
            getOptionLabel={(option) => option.tag.label}
            defaultValue={[]}
            filterSelectedOptions
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Tags"
                />
            )}
        />
    )
}
