import {getDatabase, push, ref, set, update} from "firebase/database";
import {Media, mediaFactory, Tag, TagData, tagFactory} from "../model/media";
import {firebaseApp} from "./firebase";
import {useList, useListVals, useObject} from 'react-firebase-hooks/database';
import {List, Map} from "immutable";
import {useMemo} from "react";
import {Playlist, playlistFactory, Track, trackFactory} from "../model/playlist";
import {
    Player,
    PlayerAction,
    PlayerState,
    playerStateFactory, playerStatePlayingFactory,
    PlayerStatusState,
    PlayerTrack,
    playerTrackFactory
} from "../model/Player";

const db = getDatabase(firebaseApp);

export async function writeTag(tag: Tag) {
    await set(ref(db, 'tags/' + tag.id), {
        id: tag.id,
        label: tag.label
    });
}

export async function writeMedia(media: Media) {
    await set(ref(db, 'medias/' + media.id), {
        id: media.id,
        name: media.name,
        filename: media.filename,
        duration: media.duration,
        size: media.size,
        creationDate: media.creationDate.toISOString(),
        deleted: media.deleted,
        url: media.url
    });
}

export async function deleteMedias(medias: List<Media>) {

    const updateMedias: {[keys: string]: boolean} = {}
    medias.forEach((media) => {
        updateMedias[`medias/${media.id}/deleted`] = true
    })
    await update(ref(db), updateMedias);
}

export async function setDisablePlayerTrack(player: Player, id: string, disable: boolean) {
    const updateData = {
        [`playerTracks/${player.id}/${id}/disabled`]: disable
    }
    await update(ref(db), updateData);
}

export async function writePlaylist(playlist: Playlist) {
    const jsonPlaylist = {
        ...playlist.toJSON(),
        tracks: playlist.tracks.map((track) => track.toJSON()).toArray()
    }
    await set(ref(db, 'playlists/' + playlist.id), jsonPlaylist);
}


export async function writeMediaTags(media: Media, tags: List<Tag>) {
    const db = getDatabase(firebaseApp);
    const mediaTags: { [keys: string]: { id: string, label: string } } = {};
    for (let i = 0; i < tags.size; i++) {
        const tag = tags.get(i);
        if (tag) {
            mediaTags[tag.id] = {
                id: tag.id,
                label: tag.label
            };
        }
    }
    await set(ref(db, 'mediaTags/' + media.id), mediaTags);
}

export function useTags() {
    return useList(ref(db, 'tags'));
}

export function usePlaylists(): [List<Playlist>, boolean, Error | undefined] {
    const [dbPlaylists, loading, error] = useList(ref(db, 'playlists'));
    const playlists: List<Playlist> = useMemo(() => {
        if (!dbPlaylists) return List();
        return List(dbPlaylists.map((dbPlaylist) => {
            let playlist = dbPlaylist.val();
            return playlistFactory(playlist)
        })).filter((playlist) => !playlist.deleted);
    }, [dbPlaylists])
    return [playlists, loading, error];
}

export function usePlaylist(playlistId: string | null): [Playlist | null, boolean, Error | undefined] {

    const ret = {
        playlist: null as Playlist | null,
        loading: false,
        error: undefined as Error | undefined,
    }

    const playlistRef = playlistId ? ref(db, 'playlists/' + playlistId) : null;
    const [snapshot, loading, error] = useObject(playlistRef);

    ret.loading = loading
    ret.error = error;

    if (snapshot) {
        const dbPlaylist = snapshot.val();
        if (dbPlaylist) {
            const dbTracks = dbPlaylist.tracks ? dbPlaylist.tracks : [];
            const tracks = List(dbTracks.map((dbTrack: any) => trackFactory(dbTrack)))
            ret.playlist = playlistFactory({...dbPlaylist, tracks});
        }
    }

    return [ret.playlist, ret.loading, ret.error];
}

export function useMedias(): [List<Media>, boolean, Error | undefined, Map<string, Media>] {
    const [dbMedias, mediasLoading, mediasError] = useList(ref(db, 'medias'));
    const medias: List<Media> = useMemo(() => {
        if (!dbMedias) return List();
        return List(dbMedias.map((dbMedia) => {
            const media = dbMedia.val();
            media.creationDate = new Date(media.creationDate)
            return mediaFactory(media)
        })).filter((media) => !media.deleted);
    }, [dbMedias])

    const mediasIndex = medias.reduce((idx, media) => {
        return idx.set(media.id, media);
    }, Map<string, Media>())
    return [medias, mediasLoading, mediasError, mediasIndex];
}

export function useMedia(mediaId: string | null): [Media | null, boolean, Error | undefined] {

    const ret = {
        media: null as Media | null,
        loading: false,
        error: undefined as Error | undefined,
    }

    const mediaRef = mediaId ? ref(db, 'medias/' + mediaId) : null;
    const [snapshot, loading, error] = useObject(mediaRef);

    ret.loading = loading
    ret.error = error;

    if (snapshot) {
        const dbMedia = snapshot.val();
        if (dbMedia) {
            ret.media = mediaFactory(dbMedia)
        }
    }
    return [ret.media, ret.loading, ret.error];
}

export function useMediaTags(media: Media): [List<Tag>, boolean, Error | undefined] {
    const [dbTags, mediasLoading, mediasError] = useList(ref(db, 'mediaTags/' + media.id));
    const tags: List<Tag> = useMemo(() => {
        if (!dbTags) return List();
        return List(dbTags.map((dbTag) => {
            return tagFactory(dbTag.val())
        }));
    }, [dbTags])
    return [tags, mediasLoading, mediasError];
}

export function useMediasTags(): [Map<string, List<Tag>>, boolean, Error | undefined] {
    const [dbMediaTags, loading, error] = useObject(ref(db, 'mediaTags'));
    const index: Map<string, List<Tag>> = useMemo(() => {
        if (!dbMediaTags) return Map();
        const val = dbMediaTags.val();
        if(!val) return Map();
        return Object.entries(val).reduce((index, entry: [string, TagData]) => {
            const tagList  = List(Object.values(entry[1]).map((tagData) => {
                return tagFactory(tagData);
            }));
            return index.set(entry[0], tagList);
        }, Map<string, List<Tag>>());
    }, [dbMediaTags])
    return [index, loading, error];
}


export async function writePlayerState(player: Player, playerState: PlayerState) {
    await set(ref(db, 'playerState/' + player.id), playerState.toJSON());
}

export function usePlayerState(player: Player): [PlayerState, boolean, Error | undefined] {
    const ret = {
        state: null as PlayerState | null,
        loading: false,
        error: undefined as Error | undefined,
    }

    const stateRef = player ? ref(db, 'playerState/' + player.id) : null;
    const [snapshot, loading, error] = useObject(stateRef);

    ret.loading = loading
    ret.error = error;

    if (snapshot) {
        const state = snapshot.val();
        if (state) {
            ret.state = playerStateFactory({
                ...state,
                playing: playerStatePlayingFactory(state.playing),
                nextTrack: playerTrackFactory(state.nextTrack),
                currentTrack: playerTrackFactory(state.currentTrack)
            })
        }
    }
    if (!ret.state) ret.state = playerStateFactory();
    return [ret.state, ret.loading, ret.error];
}


export async function writePlayerAction(player: Player, action: PlayerAction) {
    const playerActionsRef = ref(db, 'playerActions/' + player.id);
    const playerActionRef = push(playerActionsRef);
    action = action.merge({date: new Date()})
    const val: any = action.toJSON()
    val.date = val.date.toISOString();
    val.id = playerActionRef.key;
    await set(playerActionRef, val);
}

export function usePlayerTracks(player: Player): [List<PlayerTrack>, boolean, Error | undefined] {
    const [dbPlayerTracks, loading, error] = useList(ref(db, `/playerTracks/${player.id}`));
    const playerTracks: List<PlayerTrack> = useMemo(() => {
        if (!dbPlayerTracks) return List();
        return List(dbPlayerTracks.map((dbPlayerTrack) => {
            return playerTrackFactory(dbPlayerTrack.val())
        }))
    }, [dbPlayerTracks])
    return [playerTracks, loading, error];
}

export async function writePlayerTracks(player: Player, tracks: List<PlayerTrack>) {
    const playerTracksRef = ref(db, `/playerTracks/${player.id}`);
    const updates = {
        [`/playerTracks/${player.id}`]: tracks.map((track, index) =>{
            return track.merge({id:`${index}`}).toJSON()
        })
    };
    await update(ref(db), updates);
}