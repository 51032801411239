import {Box, LinearProgress, LinearProgressProps, Paper} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {List, Record, RecordOf} from "immutable";
import Typography from "@mui/material/Typography";
import {Media, mediaFactory} from "../model/media";
import Utils from "../core/utils";
import {uploadFile} from "../core/firebase";
import { getDownloadURL } from 'firebase/storage';
import {writeMedia} from "../core/db";


async function getVideoData(file: File) {
    return new Promise<number>((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            const duration = video.duration;
            resolve(Math.round(duration * 1000));
        }
        video.src = URL.createObjectURL(file);
    })
}

interface UploadFileData {
    file: File | null,
    progress: number,
    media: Media
}

type UploadFile = RecordOf<UploadFileData>;
const uploadFileFactory = Record<UploadFileData>({
    file: null,
    progress: 0,
    media: mediaFactory()
})

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

interface UploadProps {
    files: File[],
    handleFileComplete: (file: File) => void
}

export default function Upload({files, handleFileComplete}: UploadProps) {
    const [uploadFiles, setUploadFiles] = useState<List<UploadFile>>(List())

    const handleProgress = useCallback((file: File, progress: number) => {
        setUploadFiles((uploadFiles) => {
            return uploadFiles.map((uploadFile) => {
                if(uploadFile.file === file) {
                    return uploadFile.merge({
                        progress
                    })
                }
                return uploadFile;
            })
        })
    }, []);

    const handleComplete = useCallback((media, file) => {
        writeMedia(mediaFactory({
            id: media.id,
            name: media.name,
            filename: media.filename,
            size: media.size,
            duration: media.duration,
            creationDate: new Date(),
            url: media.url
        })).catch(console.error)

        handleFileComplete(file)

        setUploadFiles((uploadFiles) => {
            return uploadFiles.filter((uploadFile) => {
                return uploadFile.file === file
            })
        })

    }, [handleFileComplete]);

    const handleError = useCallback((file: File, error: Error) => {
        console.log(file);
    }, []);

    useEffect(() => {
        setUploadFiles((uploadFiles) => {
            let newFiles = uploadFiles;
            const currentUploadFiles = uploadFiles.map((uploadFile) => uploadFile.file)
            files.forEach((file) => {
                if (!currentUploadFiles.includes(file)) {
                    let media = mediaFactory().merge({
                        'id': Utils.uuid(),
                        'name': file.name,
                        'filename': file.name,
                        'size': file.size,
                        'creationDate': new Date()
                    });

                    newFiles = newFiles.push(uploadFileFactory({
                        file,
                        media: media
                    }))

                    getVideoData(file).then((duration) => {
                        const uploadTask = uploadFile(file, media.id);
                        uploadTask.on('state_changed',
                            (snapshot) => {
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                handleProgress(file, progress);
                            },
                            (error: Error) => {
                                handleError(file, error)
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    console.log('downlaodURl', downloadURL)
                                    handleComplete(media.merge({
                                        'url': downloadURL,
                                        duration
                                    }), file);
                                }).catch((error) => {
                                    handleError(file, error)
                                })
                            }
                        );
                    })
                }
            });
            return newFiles;
        })
    }, [files]);

    return (
        <Paper
            sx={{
                display: uploadFiles.size?'block':'none',
                mt: 2,
                mb: 2,
                p: 2,
            }}
        >
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Uploads
            </Typography>
            {uploadFiles.map((file) => (
                <Box key={file.media.id}>
                    {file.media.name}
                    <LinearProgressWithLabel value={file.progress}/>
                </Box>
            ))}
        </Paper>
    )
}