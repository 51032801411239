import {List, Record, RecordOf} from "immutable";

export interface TagData {
    id: string,
    label: string
}

export type Tag = RecordOf<TagData>;
export const tagFactory = Record<TagData>({
    id: "",
    label: ""
});


export interface MediaData {
    id: string,
    name: string,
    filename: string,
    size: number,
    duration: number
    creationDate: Date,
    deleted: boolean,
    url: string
}

export type Media = RecordOf<MediaData>;
export const mediaFactory = Record<MediaData>({
    id: "",
    name: "",
    filename: "",
    size:0,
    duration: 0,
    creationDate: new Date(),
    deleted: false,
    url: ''
});
