import {v4 as uuid} from 'uuid';
import {DateTime} from "luxon";

export default class Utils {

    static uuid() {
        return uuid();
    }

    static getNowDayString() {
        return DateTime.now().toFormat("cccc dd LLLL yyyy");
    }

    static getNextName(prefix: string, names: string[]) {
        let suffix = "";
        const goodPrefix = names.filter((name) => name.trim().startsWith(prefix));
        if (goodPrefix.length > 0) suffix = " (" + goodPrefix.length + ")"
        return prefix + suffix;
    }
}