import {List, Record, RecordOf} from "immutable";
import * as stream from "stream";

export type PlayerStatusState = 'PLAY' | 'PAUSE' | 'STOP'

export interface PlayerData {
    id: string,
    name: string
}

export type Player = RecordOf<PlayerData>;
export const playerFactory = Record<PlayerData>({
    id: "",
    name: ""
});


export interface PlayerTrackData {
    id: string,
    url: string,
    trackId: string,
    playlistId: string,
    mediaId: string,
    disabled: boolean
}

export type PlayerTrack = RecordOf<PlayerTrackData>;
export const playerTrackFactory = Record<PlayerTrackData>({
    id: '',
    url: '',
    trackId: '',
    playlistId: '',
    mediaId: '',
    disabled: false,
});

export interface PlayerStatePlayingData {
    playlistId: string | null,
    trackId: string | null,
    mediaId: string | null,
    currentPosition: number | null,
    progress: number | null,
    duration: number | null,
    isEvent: boolean
}

type PlayerStatePlaying = RecordOf<PlayerStatePlayingData>;

export const playerStatePlayingFactory = Record<PlayerStatePlayingData>({
    playlistId: null,
    trackId: null,
    mediaId: null,
    currentPosition: null,
    progress: null,
    duration: null,
    isEvent: false
});

export interface PlayerStateData {
    status: PlayerStatusState,
    ip: string | null,
    playing: PlayerStatePlaying,
    currentTrack: PlayerTrack,
    nextTrack: PlayerTrack
}

export type PlayerState = RecordOf<PlayerStateData>;
export const playerStateFactory = Record<PlayerStateData>({
    status: 'PAUSE',
    playing: playerStatePlayingFactory(),
    ip: null,
    currentTrack: playerTrackFactory(),
    nextTrack: playerTrackFactory(),
});

export interface PlayerActionData {
    type: 'PLAY' | 'PAUSE' | 'STOP' | 'NEXT' | 'PREV' | 'GOTO' | 'FIRST',
    date: Date,
    id: string,
    playerTrackId: string
}

export type PlayerAction = RecordOf<PlayerActionData>;
export const playerActionFactory = Record<PlayerActionData>({
    id: '',
    type: 'PAUSE',
    date: new Date(),
    playerTrackId: ''
});


