import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import {uploadBytesResumable} from "@firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyAE0qlNRJV180O9jaHBv50wJPI8WkAadok",
    authDomain: "b2b-stadum-demo.firebaseapp.com",
    projectId: "b2b-stadum-demo",
    storageBucket: "b2b-stadum-demo.appspot.com",
    messagingSenderId: "821389752204",
    databaseURL: "https://b2b-stadum-demo-default-rtdb.europe-west1.firebasedatabase.app/",
    appId: "1:821389752204:web:3b578c45576cfdd255c8a9"
};

export const firebaseApp = initializeApp(firebaseConfig);

const storage = getStorage(firebaseApp);

export function uploadFile(file: File, path: string){
    const storageRef = ref(storage, path);
    return uploadBytesResumable(storageRef, file);
}
