import * as React from 'react';
import {CSSProperties, useCallback} from 'react';
import {Box, IconButton, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {Playlist} from "../model/playlist";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {usePlaylists} from "../core/db";

interface PlaylistItemProps {
    playlist: Playlist,
    handleAddPlaylist?: (playlist: Playlist) => void
}

function PlaylistItem({playlist, handleAddPlaylist}: PlaylistItemProps) {

    const onTransferClick = useCallback(() => {
        handleAddPlaylist?.(playlist);
    }, [playlist, handleAddPlaylist])

    return (
        <Paper sx={{
            width: "100%",
            display: "flex",
            '&:hover .transferButton': {
                visibility: 'visible'
            },
            mt: 1,
            whiteSpace: 'nowrap',
        }}>
            <Box sx={{
                flexGrow: 1,
                p: 1,
                overflow: "hidden",
                textOverflow: "ellipsis"
            }} title={playlist.name}>
                {playlist.name}
            </Box>
            <Box sx={{
                visibility: 'hidden'
            }}
                 className='transferButton'
            >
                <IconButton onClick={onTransferClick}>
                    <PlayArrowIcon/>
                </IconButton>
            </Box>
        </Paper>
    )
}

interface PlaylistSelectorProps {
    handleAddPlaylist: (playlist: Playlist) => void
}

export default function PlaylistSelector({handleAddPlaylist}: PlaylistSelectorProps) {

    const [playlists, playlistsLoading, playlistsError] = usePlaylists();

    return (

        <Box sx={{
            position: "sticky",
            top: 0,
            height: "100vh",
            minWidth: '20rem',
            maxWidth: '20rem',
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            flexShrink: 0,
            flexGrow: 0
        }}>

            <Box
                sx={{
                    mt: 10,
                    flexGrow: 1,
                    overflow: "hidden",
                    overflowY: "scroll",
                    p: 2
                }}
            >
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Playlists
                </Typography>
                {playlists.map((playlist, index) => (
                    <Box key={playlist.id}>
                        <PlaylistItem playlist={playlist} handleAddPlaylist={handleAddPlaylist}/>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}