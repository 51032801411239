import React, {useCallback, useMemo} from 'react'
import ResponsiveAppBar from "../components/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
    Box,
    ButtonGroup,
    Container, Grid,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import {playerActionFactory, playerFactory, PlayerTrack, playerTrackFactory} from "../model/Player";
import {
    setDisablePlayerTrack,
    useMedia,
    useMedias,
    usePlayerState,
    usePlayerTracks,
    usePlaylist,
    usePlaylists,
    writePlayerAction,
    writePlayerTracks
} from "../core/db";
import {Duration} from "luxon";
import PlaylistSelector from "../components/PlaylistSelector";
import {Playlist} from "../model/playlist";
import {MediaModal, useMediaModal} from "../components/MediaModal";
import PreviewIcon from "@mui/icons-material/Preview";
import Live from "../components/Live";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


const ACTION_PLAY = playerActionFactory({type: 'PLAY'})
const ACTION_PAUSE = playerActionFactory({type: 'PAUSE'})
const ACTION_STOP = playerActionFactory({type: 'STOP'})
const ACTION_NEXT = playerActionFactory({type: 'NEXT'})
const ACTION_PREV = playerActionFactory({type: 'PREV'})
const ACTION_FIRST = playerActionFactory({type: 'FIRST'})
const ACTION_GOTO = playerActionFactory({type: 'GOTO'})

const DEFAULT_PLAYER = playerFactory({id: 'DEFAULT', name: 'DEFAULT'});

interface PlayerTrackProps {
    playerTrack: PlayerTrack,
    isPlaying: boolean,
    onTrackPlayClick: (playerTrack: PlayerTrack) => void,
    onMediaModalClick: (mediaId: string) => void,
    onToggleDisableTrackClick: (playerTrack: PlayerTrack) => void,
}


export function PlayerTrack({
                                playerTrack,
                                isPlaying,
                                onTrackPlayClick,
                                onMediaModalClick,
                                onToggleDisableTrackClick
                            }: PlayerTrackProps) {

    const [media] = useMedia(playerTrack.mediaId);
    const [playlist] = usePlaylist(playerTrack.playlistId);

    const color = useMemo(() => {
        if (!playlist) return 'white';
        for (let i = 0; i < playlist.tracks.size; i++) {
            const track = playlist.tracks.get(i);
            if (track && track.id === playerTrack.trackId) return track.color;
        }
    }, [playerTrack, playlist])

    const durationStr = useMemo(() => {
        if (!media) return '';
        return Duration.fromObject({milliseconds: media?.duration}).toFormat("m:ss")
    }, [media])

    const handlePlayClick = useCallback(() => {
        onTrackPlayClick(playerTrack)
    }, [playerTrack, onTrackPlayClick])

    const handleDeleteClick = useCallback(() => {
        onToggleDisableTrackClick(playerTrack);
    }, [playerTrack])

    return (<TableRow
        selected={isPlaying}
    >
        <TableCell
            sx={{
                borderLeft: "1.5rem solid",
                borderLeftColor: color || 'white' //TODO get theme color
            }}

        >
            {media?.name}
        </TableCell>
        <TableCell>{durationStr}</TableCell>
        <TableCell
            sx={{
                width: "1rem",
                whiteSpace: "nowrap",
            }}
        >
            <ButtonGroup size="small" variant="outlined" aria-label="">
                <IconButton
                    aria-label="preview"
                    onClick={() => media && onMediaModalClick(media.id)}
                >
                    <PreviewIcon/>
                </IconButton>
                <IconButton
                    aria-label="delete"
                    onClick={handleDeleteClick}
                >
                    {!playerTrack.disabled && <VisibilityIcon/>}
                    {playerTrack.disabled && <VisibilityOffIcon/>}
                </IconButton>
                <IconButton
                    aria-label="play"
                    onClick={handlePlayClick}
                >
                    <PlayArrowIcon/>
                </IconButton>
            </ButtonGroup>
        </TableCell>
    </TableRow>)
}


export default function BroadcastPage() {

    const [playerState, playerStateLoading, playerStateError] = usePlayerState(DEFAULT_PLAYER);
    const [playlists, playlistsLoading, playlistsError] = usePlaylists();
    const [medias, mediasLoading, mediasError, mediasIndex] = useMedias();

    const [playerTracks, playerTracksLoading, playerTracksError] = usePlayerTracks(DEFAULT_PLAYER);


    const handlePlayClick = useCallback(() => {
        writePlayerAction(DEFAULT_PLAYER, ACTION_PLAY).catch(console.error);
    }, [])

    const handlePauseClick = useCallback(() => {
        writePlayerAction(DEFAULT_PLAYER, ACTION_PAUSE).catch(console.error);
    }, [])

    const handleStopClick = useCallback(() => {
        writePlayerAction(DEFAULT_PLAYER, ACTION_STOP).catch(console.error);
    }, [])

    const handlePrevTrackClick = useCallback(() => {
        writePlayerAction(DEFAULT_PLAYER, ACTION_PREV).catch(console.error);
    }, [])

    const handleNextTrackClick = useCallback(() => {
        writePlayerAction(DEFAULT_PLAYER, ACTION_NEXT).catch(console.error);
    }, [])

    const handleFirstTrackClick = useCallback(() => {
        writePlayerAction(DEFAULT_PLAYER, ACTION_FIRST).catch(console.error);
    }, [])

    const handleTrackPlayClick = useCallback((playerTrack: PlayerTrack) => {
        writePlayerAction(DEFAULT_PLAYER, ACTION_GOTO.merge({playerTrackId: playerTrack.trackId})).catch(console.error);
    }, [])

    const handleToggleDisableTrackClick = useCallback((playerTrack: PlayerTrack) => {
        setDisablePlayerTrack(DEFAULT_PLAYER, playerTrack.id, !playerTrack.disabled).catch(console.error);
    }, [])

    const handleAddPlaylist = useCallback((playlist: Playlist) => {
        if (playlist && medias) {
            const playerTracks = playlist.tracks.map((track) => {
                const media = mediasIndex.get(track.mediaId);
                return playerTrackFactory({
                    url: media?.url || '',
                    playlistId: playlist.id,
                    trackId: track.id,
                    mediaId: track.mediaId
                })
            }).filter((track) => track !== null);
            writePlayerTracks(DEFAULT_PLAYER, playerTracks).catch(console.error)
        }
    }, [medias, mediasIndex]);

    const playingTracks = useMemo(() => {
        return {
            current: {
                media: mediasIndex.get(playerState.currentTrack.mediaId) || null
            },
            next: {
                media: mediasIndex.get(playerState.nextTrack.mediaId) || null
            }
        }
    }, [playerState.currentTrack, playerState.nextTrack, mediasIndex])


    //////////////////////////

    const [mediaModalState, handleMediaModalClose, handleMediaModalClick] = useMediaModal();


    const vncHost = useMemo(() => {
        if (playerState.ip === null) return null;
        return `ws://${playerState.ip}:6080`
    }, [playerState.ip])

    return (
        <>
            <Box sx={{display: 'flex'}}>
                <ResponsiveAppBar/>
                <PlaylistSelector handleAddPlaylist={handleAddPlaylist}/>
                <Container sx={{p: 3, flexGrow: 1, backgroundColor: '#f5f5f5', minHeight: '100vh'}}>
                    <Toolbar/>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Typography variant="h4">
                            Broadcast
                        </Typography>
                        <Box sx={{textAlign: 'right', flexGrow: 1}}>
                            <ButtonGroup variant="contained" aria-label="outlined primary button group"
                                         sx={{
                                             backgroundColor: 'white'
                                         }}
                            >
                                <IconButton aria-label="play" onClick={handleFirstTrackClick}>
                                    <FirstPageIcon/>
                                </IconButton>
                                <IconButton aria-label="play" onClick={handlePrevTrackClick}>
                                    <SkipPreviousIcon/>
                                </IconButton>
                                {playerState.status !== 'PLAY' &&
                                    <IconButton aria-label="play" onClick={handlePlayClick}>
                                        <PlayArrowIcon/>
                                    </IconButton>
                                }
                                {playerState.status !== 'PAUSE' && playerState.status !== 'STOP' &&
                                    <IconButton aria-label="pause" onClick={handlePauseClick}>
                                        <PauseIcon/>
                                    </IconButton>
                                }
                                <IconButton aria-label="stop" onClick={handleStopClick}>
                                    <StopIcon/>
                                </IconButton>
                                <IconButton aria-label="play" onClick={handleNextTrackClick}>
                                    <SkipNextIcon/>
                                </IconButton>
                            </ButtonGroup>
                        </Box>
                    </Box>

                    <Paper
                        sx={{
                            mt: 2,
                            mb: 2,
                            p: 2,
                            overflow: 'hidden',
                            minHeight: '170px'
                        }}
                    >
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Live
                        </Typography>
                        <Box sx={{
                            height: '350px',
                            overflowX: "auto"
                        }}>
                            {vncHost && <Live host={vncHost}/>}
                        </Box>

                        <Paper sx={{p: 1, mb: 1}}>
                            <Typography variant="subtitle2" color="primary" gutterBottom>
                                En cours de diffusion
                            </Typography>
                            <Grid container>
                                <Grid item sm={6}>
                                    <Typography variant="subtitle1" color="text.primary" gutterBottom>
                                        {playingTracks.current.media?.name || 'Aucune piste sélectionnée pour diffusion'}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} sx={{textAlign: 'right'}}>
                                    <Typography variant="subtitle1" color="text.primary" gutterBottom>
                                        {Duration.fromObject({milliseconds: playerState.playing.currentPosition || 0}).toFormat("m:ss")}
                                        &nbsp;/&nbsp;
                                        {Duration.fromObject({milliseconds: playerState.playing.duration || 0}).toFormat("m:ss")}
                                    </Typography>
                                </Grid>

                            </Grid>
                            <LinearProgress
                                sx={{
                                    'span': {transition: 'none'},
                                    height: '8px'
                                }}
                                variant="determinate"
                                value={playerState.status === 'STOP' ? 0 : (playerState.playing.progress || 0)}
                            />
                        </Paper>

                        <Paper sx={{p: 1, mb: 1}}>
                            <Typography variant="subtitle2" color="primary" gutterBottom>
                                Diffusion suivante
                            </Typography>
                            <Grid container>
                                <Grid item sm={6}>
                                    <Typography variant="subtitle1" color="text.primary" gutterBottom>
                                        {playingTracks.next.media?.name || 'Aucune piste sélectionnée pour diffusion'}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} sx={{textAlign: 'right'}}>
                                    <Typography variant="subtitle1" color="text.primary" gutterBottom>
                                        {Duration.fromObject({milliseconds: 0}).toFormat("m:ss")}
                                        &nbsp;/&nbsp;
                                        {Duration.fromObject({milliseconds: playingTracks.next.media?.duration || 0}).toFormat("m:ss")}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Paper>


                    </Paper>

                    <Paper
                        sx={{
                            mt: 2,
                            mb: 2,
                            p: 2,
                        }}
                    >
                        <Box sx={{display: 'flex'}}>
                            <Box>
                                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                    Pistes
                                </Typography>
                            </Box>
                        </Box>


                        <TableContainer
                            sx={{overflow: 'hidden'}}
                        >
                            <Table
                                sx={{minWidth: 750}}
                                aria-labelledby="tableTitle"
                                size={'small'}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Nom</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>Durée</strong>
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {playerTracks.map((playerTrack) => (
                                        <PlayerTrack
                                            playerTrack={playerTrack}
                                            key={playerTrack.trackId}
                                            isPlaying={playerTrack.trackId === playerState.playing.trackId}
                                            onTrackPlayClick={handleTrackPlayClick}
                                            onMediaModalClick={handleMediaModalClick}
                                            onToggleDisableTrackClick={handleToggleDisableTrackClick}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                </Container>
            </Box>

            <MediaModal open={mediaModalState.open} mediaId={mediaModalState.mediaId}
                        handleClose={handleMediaModalClose}

            />
        </>
    );
}